<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="popup">
    <div style="margin-bottom: 2%">
      <div style="justify-content: end; display: flex">
        <div style="width:10%">
          <img
            width="60px"
            height="auto"
            :src="urlLogo"
          />
        </div>
        <div style="width:30%">
          <h3>{{this.$userLogin.company_name}}</h3>
        </div>
        <div style="width:60%" align="right">
          <i>
            <h4>Invoice</h4>
          </i>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div style="width: 50%">
        <h4 style="margin-bottom: 2%; border-bottom: 1px solid black">ORDERED BY</h4>
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">Company</td>
              <td class="color">{{invoice.client.code}}</td>
            </tr>
            <tr>
              <td width="25%">Name</td>
              <td class="color">{{invoice.client.name}}</td>
            </tr>
            <tr>
              <td width="25%">Address</td>
              <td class="color">{{invoice.client.address}}</td>
            </tr>
            <tr>
              <td width="25%">Phone</td>
              <td class="color">{{invoice.client.phone}}</td>
            </tr>
            <tr>
              <td width="25%">Note</td>
              <td class="color"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="this.$route.name == 'invoice-print'" style="width: 50%">
        <h4 style="margin-bottom: 2%; border-bottom: 1px solid black">&nbsp;</h4>
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">Delivery Number</td>
              <td class="color">{{invoice.delivery_order.DeliveryPlanCode}}</td>
            </tr>
            <tr>
              <td width="25%">Delivery Date</td>
              <td class="color">{{invoice.due_date}}</td>
            </tr>
            <tr>
              <td width="25%">DO Number</td>
              <td class="color">{{invoice.delivery_order.Code}}</td>
            </tr>
            <tr>
              <td width="25%">DO Date</td>
              <td class="color">{{invoice.date}}</td>
            </tr>
            <tr>
              <td width="25%">SO Number</td>
              <td class="color">{{invoice.sales_order[0].Code}}</td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="this.$route.name == 'invoice-print'"  class="d-flex">
      <div style="width: 50%">
        <h4 style="margin-bottom: 2%; border-bottom: 1px solid black">DELIVERY FROM</h4>
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">Code</td>
              <td class="color">{{invoice.company_code}}</td>
            </tr>
            <tr>
              <td width="25%">Name</td>
              <td class="color">{{invoice.company_name}}</td>
            </tr>
            <!-- <tr>
              <td width="25%">Address</td>
              <td class="color">Jl. Menteng No. 30, RT.01 RW.02, Jakarta Pusat</td>
            </tr> -->
            <!-- <tr>
              <td width="25%">Phone</td>
              <td class="color">031-85677765</td>
            </tr> -->
          </tbody>
        </table>
      </div>
      <div style="width: 50%">
        <h4 style="margin-bottom: 2%; border-bottom: 1px solid black">DELIVERY TO</h4>
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">Company</td>
              <td class="color">{{invoice.client.code}}</td>
            </tr>
            <tr>
              <td width="25%">Name</td>
              <td class="color">{{invoice.client.name}}</td>
            </tr>
            <tr>
              <td width="25%">Address</td>
              <td class="color">{{invoice.client.address}}</td>
            </tr>
            <tr>
              <td width="25%">Phone</td>
              <td class="color">{{invoice.client.phone}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <div>
      <div>
        <table class="padding-table" style="width: 100%; border-collapse: collapse;">
          <tbody>
            <tr class="text-center color">
              <td style="border: 1px solid black;">
                <strong>QTY</strong>
              </td>
              <td style="border: 1px solid black;">
                <strong>SKU</strong>
              </td>
              <td style="border: 1px solid black;">
                <strong>DESCRIPTION</strong>
              </td>
              <td style="border: 1px solid black;">
                <strong>UNIT PRICE</strong>
              </td>
              <td style="border: 1px solid black;">
                <strong>TOTAL</strong>
              </td>
            </tr>
            <template v-for="(sales_order) in invoice.sales_order" >
              <tr v-if="$route.name != 'invoice-print'"  :key="sales_order.Code">
                  <td  style="border-top: 1px  solid black;border-bottom: 1px solid black;border-left: 1px solid black; border-right: 1px solid black;" colspan="5"  align="center">{{sales_order.Code}}</td>
              </tr>
              <template v-for="(dt, dtIndex) in sales_order.Line">
                  <tr :key="dtIndex" >
                      <td style="border-left: 1px solid black; border-right: 1px solid black;" >{{dt.Quantity}}</td>
                      <td style="border-left: 1px solid black; border-right: 1px solid black;" >{{dt.SkuCode}}-{{dt.ItemName}} ({{dt.Unit}})</td>
                      <td style="border-left: 1px solid black; border-right: 1px solid black;" >-</td>
                      <td  style="border-left: 1px solid black; border-right: 1px solid black;" align="right">{{priceFormat(dt.SellPrice)}}</td>
                      <td  style="border-left: 1px solid black; border-right: 1px solid black;" align="right" >{{priceFormat(dt.Subtotal)}}</td>
                  </tr>
              </template>
            </template>
            <tr>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;"
              ></td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;"
              ></td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;"
              ></td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;"
              ></td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;"
              ></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>SUBTOTAL</td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;text-align:right;"
              >{{ priceFormat(invoice.subtotal) }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>DICOUNT</td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;text-align:right;"
              >{{ priceFormat(invoice.discount) }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>TAX ({{invoice.tax_rate}}%)</td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;text-align:right;"
              >{{ priceFormat(invoice.tax_amount) }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>TOTAL</td>
              <td
                style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;text-align:right;"
              >{{ priceFormat(invoice.total) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="margin-top: 5%;">
      <table width="100%">
        <tbody>
          <tr>
            <td width="15%">Received By</td>
            <td width="25%" class="color"></td>
            <td width="30%"></td>
            <!-- <td>Thursday, June 4th 2020</td> -->
            <td> {{now}}</td>
          </tr>
          <tr>
            <td>Signature</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style="height:70px"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2">
              <strong>RECIEVED IN GOOD CONDITION</strong>
            </td>
            <td></td>
            <td>
              <strong>{{invoice.company_name}}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      cdn :'https://cdn.dbms.co.id/uploads/company/logo/',
      company:'',
      hari:'',
      bulan:'',
      tahun:'',
      tanggal:'',
      now:'',
      invoice: {
        id: 0,
        code: "",
        faktur_number: "",
        company: {
          code: "",
          name: ""
        },
        delivery_order:{},
        client: {
          id: "",
          code: "",
          name: ""
        },
        bill_to: {},
        notes: "",
        date: "",
        due_date: "",
        time: "",
        subtotal: 0,
        tax_rate: 0,
        tax_amount: 0,
        discount: 0,
        other_cost: 0,
        total: 0,
        status: 0,
        sales_order: [],
        delete_sales_order: []
      }
    };
  },
  computed: {
    urlLogo:function(){
      console.log(this.$userLogin)
      console.log(this.company)
      return (this.company==undefined?'default.png':this.company.company_logo);
    },
  },
  methods: {
    printInvoice() {
      window.print();
    },
    dateFormat(date) {
        if (date) {
            // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
            let dt = new Date(date)
            let d = dt.getDate()
            let m = dt.getMonth()+1
            let y = dt.getFullYear()
            return d+"/"+m+"/"+y
        }
    },
    invoiceValue(invoice) {
      console.log(invoice, 'invoice')
      this.invoice.items = [];
      this.invoice.delete_items = [];
      this.invoice.id = invoice.invoice.ID;
      this.invoice.code = invoice.invoice.Code;
      this.invoice.delivery_order = invoice.deliveryOrder[0];
      console.log("3")
      this.invoice.faktur_number = invoice.invoice.FakturNumber
        this.invoice.client = {
          id: invoice.customer.ID,
          address: invoice.customer.address,
          phone:invoice.customer.phone,
          code: invoice.invoice.ClientCode,
          name: invoice.invoice.ClientName
        };
      this.invoice.client_code = invoice.invoice.ClientCode;
      this.invoice.client_name = invoice.invoice.ClientName;
      this.invoice.company_code = invoice.invoice.CompanyCode;
      this.invoice.company_name = invoice.invoice.CompanyName;
      this.invoice.notes = invoice.invoice.Note;
      this.invoice.date = this.dateFormat(invoice.invoice.Date.toString());
      this.invoice.due_date = this.dateFormat(invoice.invoice.DueDate.toString());
      this.invoice.time = invoice.invoice.Time;
      this.invoice.subtotal = invoice.invoice.Subtotal;
      this.invoice.tax_rate = invoice.invoice.TaxRate;
      this.invoice.tax_amount = invoice.invoice.TaxAmount;
      this.invoice.discount = invoice.invoice.Discount;
      this.invoice.other_cost = invoice.invoice.OtherCost;
      this.invoice.total = invoice.invoice.Total;
      this.invoice.tax_amount = invoice.invoice.TaxAmount;
      this.invoice.tax_rate = invoice.invoice.TaxRate;
      this.invoice.status = invoice.invoice.Status;
      this.invoice.sales_order = [];

      for (var x in invoice.invoiceLine) {
        invoice.invoiceLine[x].total = invoice.invoiceLine[x].Line.reduce(
          (acc, value) => acc + value.Total,
          0
        );
        this.invoice.sales_order.push(invoice.invoiceLine[x]);
        for (var y in this.invoice.sales_order[x].Line) {
          this.invoice.sales_order[x].Line[
            y
          ].Quantity = this.invoice.sales_order[x].Line[y].Qty;
          this.invoice.sales_order[x].Line[
            y
          ].SellPrice = this.invoice.sales_order[x].Line[y].Price;
        }
      }
      const now = new Date();
      this.now = this.dateFormat(now.toString())
      this.tahun = now.getFullYear()
      this.bulan = now.getMonth()
      this.tanggal = now.getDate()
      // window.print();
      console.log("1")
      return true;
    }
  },
  components: {},
  mounted() {
    this.company = this.$userLogin
    console.log(this.company)
    console.log("this.company")
    this.$emit("setAppClasses", "invoice-page");
    this.$http
      .get("api/v1/invoice/read", {
        params: {
          invoice_id: this.$route.params.id
        }
      })
      .then(resp => {
        if (resp.code == 200) {
          // const asyncFunction = async () => {
            this.invoiceValue(resp.data);
            console.log("promise1Result");
            // window.print();
            this.$nextTick(() => {
              window.print();
              // window.close()
            });
            console.log("promise2Result");
            this.$vs.loading.close();

          // }
          // console.log(asyncFunction)
          // this.isEditorShow = true;
        } else {
          // //console.log(resp.code)
          this.$vs.dialog({
            type: "alert",
            color: "danger",
            title: `Alert`,
            text: "Whoops"
          });
        this.$vs.loading.close();
        }
      });
    // window.print()
  }
};
</script>

<style lang="scss">
body {
  height: 297mm;
  width: 210mm;
  margin: 0 auto;
  background-color: white;
  padding: 4%;
}
div {
  margin-left: 1%;
  margin-right: 1.5%;
}

.d-flex {
  display: flex;
}

.color {
  background-color: #d0cdf2;
}

table.padding-table tr td {
  padding: 1%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

* {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@page {
  size: A4;
  margin: 0;
}

.popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 20;
  left: 0;
  // display: none;
}

.popup:nth-of-type(2) {
  page-break-before: always;
  top: 100%;
}

@media print {
  // body * {
    // visibility: hidden;
  // }
  .printable * {
    visibility: visible;
  }
  .popup {
    display: block;
  }
}

@page {
  size: auto;
}
</style>
